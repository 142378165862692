
import api from "../../../utils/api";
import { RequestParams } from "../../../utils/RequestParams";



const get = async (
parameters,









headers) =>
{
  const params = new RequestParams(parameters, headers);
  const response = await api.get('suggestions', params);
  return response.json;
};

const aggregation = async (extractorId, headers) => {
  const params = new RequestParams({ extractorId }, headers);
  const response = await api.get('suggestions/aggregation', params);
  return response.json;
};

const accept = async (
suggestions) =>
{
  const params = new RequestParams({ suggestions });
  const response = await api.post('suggestions/accept', params);
  return response.json;
};

const findSuggestions = async (extractorId, headers) => {
  const params = new RequestParams({ extractorId }, headers);
  const response = await api.post('suggestions/train', params);
  return response.json;
};

const status = async (extractorId, headers) => {
  const params = new RequestParams({ extractorId }, headers);
  const { json: response } = await api.post('suggestions/status', params);
  return response;
};

const cancel = async (extractorId, headers) => {
  const params = new RequestParams({ extractorId }, headers);
  const { json: response } = await api.post('suggestions/stop', params);
  return response;
};

export { get, accept, aggregation, findSuggestions, status, cancel };